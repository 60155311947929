import { combineReducers } from '@reduxjs/toolkit';
import billListSliceReducer from './billListSlice';
import billSliceReducer from './billSlice';

const billPageReducer = combineReducers({
  bill: billSliceReducer,
  billList: billListSliceReducer,
});

export default billPageReducer;
