import { createSlice } from '@reduxjs/toolkit';

const orderSlice = createSlice({
  name: 'orderPage/orderOne',
  initialState: {
    order: undefined,
  },
  reducers: {
    getOrder: (state, action) => {
      if (!!action.payload) {
        return {
          ...state,
          order: action.payload,
        };
      }
    },
  },
});

export const { getOrder } = orderSlice.actions;

export default orderSlice.reducer;
