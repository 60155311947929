import React from 'react';
import Icon from './';
import { ReactComponent as WarningCircleOutlined } from '@/images/dentall-components/0-icon-fill-warning-circle-fill.svg';
const CustomizeIcon = props => {
  return <Icon component={WarningCircleOutlined} {...props} />;
};

export default CustomizeIcon;
CustomizeIcon.defaultProps = {
  color: 'var(--warning-500)',
};
