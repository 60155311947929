import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@/components/Icon';
import { ReactComponent as FunnelFill } from '@/images/dentall-components/funnel-fill.svg';

const FilterIcon = ({ icon, values, size, color, fillColor }) => {
  return _.isNil(values) || _.isEmpty(values) ? (
    <Icon component={icon} size={size} color={color} />
  ) : (
    <Icon component={icon} size={size} color={fillColor} />
  );
};

export default FilterIcon;

FilterIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element]),
  values: PropTypes.array,
  size: PropTypes.number,
  color: PropTypes.string,
  fillColor: PropTypes.string,
};

FilterIcon.defaultProps = {
  icon: FunnelFill,
  values: [],
  size: 16,
  color: 'var(--disabled)',
  fillColor: 'var(--basic-800)',
};
