import React from 'react';
import Icon from './';
import { ReactComponent as SuccessCircleOutlined } from '@/images/dentall-components/0-icon-fill-check-circle-fill.svg';
const CustomizeIcon = props => {
  return <Icon component={SuccessCircleOutlined} {...props} />;
};

export default CustomizeIcon;
CustomizeIcon.defaultProps = {
  color: 'var(--success-500)',
};
