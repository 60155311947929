import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const couponSlice = createSlice({
  name: 'couponPage/coupon',
  initialState: {
    coupons: undefined,
    total: null,
    currentTotalLens: 0,
    usableCoupons: undefined,
  },
  reducers: {
    getCoupons: (state, action) => {
      if (!!action.payload) {
        return {
          ...state,
          coupons: action.payload?.results,
          total: action.payload?.total,
          currentTotalLens: !_.isNil(action.payload?.results) ? action.payload?.results.length : state.currentTotalLens,
        };
      }
    },
    getUsableCoupons: (state, action) => {
      if (!!action.payload) {
        return {
          ...state,
          usableCoupons: action.payload,
        };
      }
    },
    updateUsableCoupons: (state, action) => {
      if (!!action.payload) {
        return {
          ...state,
          usableCoupons: action.payload,
        };
      }
    },
  },
});

export const { getCoupons, getUsableCoupons, updateUsableCoupons } = couponSlice.actions;

export default couponSlice.reducer;
