import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const shopListSlice = createSlice({
  name: 'shopPage/shopList',
  initialState: {
    categoryDrawerVisible: false,
    searchKeyword: null,
    categories: undefined,
    products: undefined,
    total: null,
    prevCategoryId: null,
    currentTotalLens: 0,
    sellingProducts: {
      activity: undefined,
      featured: undefined,
    },
    boughtProducts: undefined,
  },
  reducers: {
    getSellingProducts(state, action) {
      if (!!action.payload) {
        state.sellingProducts = { ...state.sellingProducts, ...action.payload };
      }
    },
    getCategories: (state, action) => {
      if (!!action.payload) {
        return {
          ...state,
          categories: action.payload,
        };
      }
    },
    getProducts: (state, action) => {
      if (!!action.payload) {
        return {
          ...state,
          products: action.payload?.results,
          total: action.payload?.total,
          prevCategoryId: _.isNil(action.payload?.prevCategoryId) ? null : action.payload?.prevCategoryId,
          currentTotalLens: !_.isNil(action.payload?.results) ? action.payload?.results.length : state.currentTotalLens,
        };
      }
    },
    getBoughtProducts: (state, action) => {
      if (!!action.payload) {
        return {
          ...state,
          boughtProducts: action.payload,
        };
      }
    },
    updateFavoriteProducts: (state, action) => {
      if (!!action.payload) {
        return {
          ...state,
          products: action.payload,
        };
      }
    },
    updateSearchKeyword: (state, action) => {
      return {
        ...state,
        searchKeyword: action.payload,
      };
    },
    updateCategoryDrawerVisible: (state, action) => {
      return {
        ...state,
        categoryDrawerVisible: action.payload,
      };
    },
  },
});

export const {
  getSellingProducts,
  getCategories,
  getProducts,
  getBoughtProducts,
  updateFavoriteProducts,
  updateSearchKeyword,
  updateCategoryDrawerVisible,
} = shopListSlice.actions;

export default shopListSlice.reducer;
