import { createSlice } from '@reduxjs/toolkit';

const shopSlice = createSlice({
  name: 'shopPage/shop',
  initialState: {
    product: undefined,
  },
  reducers: {
    getProduct: (state, action) => {
      if (!!action.payload) {
        return {
          ...state,
          product: action.payload,
        };
      }
    },
  },
});

export const { getProduct } = shopSlice.actions;

export default shopSlice.reducer;
