import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const settingSlice = createSlice({
  name: 'homePage/settings',
  initialState: {
    // locale: navigator.language,
    locale: 'zh-TW',
    openKeysInMenu: ['clinic', 'prescriptionItems'],
    alertModalInfo: {
      open: false,
      title: undefined,
      content: undefined,
      hasAuth: false,
    },
    allowance: {
      shippingFee: {},
    },
  },
  reducers: {
    changeLocale: (state, action) => {
      state.locale = action.payload;
    },
    updateOpenKeysInMenu: (state, action) => {
      state.openKeysInMenu = action.payload;
    },
    updateAlertModal: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          alertModalInfo: { ...state.alertModalInfo, ...action.payload },
        };
      }
    },
    resetAlertModal: state => {
      return {
        ...state,
        alertModalInfo: {
          open: false,
          title: undefined,
          content: undefined,
          hasAuth: false,
        },
      };
    },
    getShippingFeeAllowance: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          allowance: {
            ...state.allowance,
            shippingFee: action.payload,
          },
        };
      }
    },
  },
});

export const { changeLocale, updateOpenKeysInMenu, updateAlertModal, resetAlertModal, getShippingFeeAllowance } =
  settingSlice.actions;
export default settingSlice.reducer;
