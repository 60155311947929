import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@/components/Icon';
import { ReactComponent as Close } from '@/images/dentall-components/icons-close.svg';

export const DeleteBtn = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const CloseBtn = ({ onClick, size }) => {
  return (
    <DeleteBtn onClick={onClick}>
      <Icon component={Close} color={'var(--basic-600)'} size={size} />
    </DeleteBtn>
  );
};

export default CloseBtn;

CloseBtn.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
};

CloseBtn.defaultProps = {
  onClick: () => {},
  size: 16,
};
