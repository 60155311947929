import { createSlice } from '@reduxjs/toolkit';

const previewOrderSlice = createSlice({
  name: 'orderPage/orderPreview',
  initialState: {
    previewOrder: undefined,
  },
  reducers: {
    getPreviewOrder: (state, action) => {
      if (!!action.payload) {
        return {
          ...state,
          previewOrder: action.payload,
        };
      }
    },
  },
});

export const { getPreviewOrder } = previewOrderSlice.actions;

export default previewOrderSlice.reducer;
