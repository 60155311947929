import device from './device';

export const useDevice = () => {
  const {
    TabletAndMobile,
    DesktopOrLaptop,
    isDesktopOrLaptop,
    isTabletAndMobile,
    isMobile,
    isTablet,
    Mobile,
    detectTouchscreen,
  } = device({
    tabletAndMobileMaxW: 1279,
    deskOrLaptopMinW: 768,
    tabletMinW: 768,
    tabletMaxW: 1279,
    mobileMaxW: 767,
  });
  return {
    TabletAndMobile,
    DesktopOrLaptop,
    isDesktopOrLaptop,
    isTabletAndMobile,
    isMobile,
    Mobile,
    isTablet,
    detectTouchscreen,
  };
};
