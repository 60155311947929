import { combineReducers } from '@reduxjs/toolkit';
import previewOrderReducer from './previewOrderSlice';
import orderSliceReducer from './orderSlice';
import orderListSliceReducer from './orderListSlice';

const orderPageReducer = combineReducers({
  previewOrder: previewOrderReducer,
  order: orderSliceReducer,
  orderList: orderListSliceReducer,
});

export default orderPageReducer;
