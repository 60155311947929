import { combineReducers } from '@reduxjs/toolkit';
import shopListReducer from './shopListSlice';
import shopReducer from './shopSlice';

const shopPageReducer = combineReducers({
  shopList: shopListReducer,
  shop: shopReducer,
});

export default shopPageReducer;
