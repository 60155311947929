import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'homePage/authentication',
  initialState: {
    fetchStatus: {},
    currentUser: null,
    successTokenData: {},
    isOk: false,
  },
  reducers: {
    appInitFetching: (state, action) => {
      return {
        ...state,
        fetchStatus: { ...state.fetchStatus, ...action.payload },
      };
    },
    authenticated: (state, action) => {
      return {
        ...state,
        isOk: action.payload,
      };
    },
    updateCurrentUser: (state, action) => {
      return {
        ...state,
        currentUser: !_.isEmpty(action.payload) ? action.payload : {},
      };
    },
    fetchStd: (state, action) => {
      return {
        ...state,
        successTokenData: action.payload,
      };
    },
  },
});

export const { appInitFetching, authenticated, fetchStd, updateCurrentUser } = authSlice.actions;

export default authSlice.reducer;
