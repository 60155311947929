import { lazy } from 'react';
import { errorStatus } from '@/constants/errorStatus';
import {
  BILL_PATH,
  CART_PATH,
  COUPON_PATH,
  DEPOSIT_PATH,
  COLLECTION_PATH,
  NOTIFY_PATH,
  ORDER_PATH,
  SEARCH_PATH,
  SHOP_PATH,
  CHECKOUT_PATH,
} from '@/constants/routePath';
const MainLayout = lazy(() => import('@/components/MainLayout'));
const HomePage = lazy(() => import('@/container/HomePage'));
const BillListPage = lazy(() => import('@/container/BillPage/BillListPage'));
const BillPage = lazy(() => import('@/container/BillPage'));
const CartPage = lazy(() => import('@/container/CartPage'));
const CheckoutPage = lazy(() => import('@/container/CartPage/CheckoutPage'));
const CouponPage = lazy(() => import('@/container/CouponPage'));
const DepositPage = lazy(() => import('@/container/DepositPage'));
const CollectionPage = lazy(() => import('@/container/CollectionPage'));
const NotifyPage = lazy(() => import('@/container/NotifyPage'));
const OrderListPage = lazy(() => import('@/container/OrderPage/OrderListPage'));
const OrderPage = lazy(() => import('@/container/OrderPage'));
const ShopListPage = lazy(() => import('@/container/ShopPage/ShopListPage'));
const ShopPage = lazy(() => import('@/container/ShopPage'));
const Error = lazy(() => import('@/components/Error'));

export const mainRoutes = ({ currentUser, matchPermissionPath }) => [
  {
    path: '/',
    name: 'main',
    key: 'main',
    element: <MainLayout currentUser={currentUser} matchPermissionPath={matchPermissionPath} />,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: SHOP_PATH,
        children: [
          { index: true, element: <ShopListPage /> },
          { path: ':shopId', element: <ShopPage /> },
        ],
      },
      {
        path: SEARCH_PATH,
        element: <ShopListPage />,
      },
      {
        path: '/:wsId',
        children: [
          {
            index: true,
            element: <Error data={{ code: errorStatus.ERR_404 }} />,
          },
          {
            path: BILL_PATH,
            key: BILL_PATH,
            children: [
              { index: true, element: <BillListPage /> },
              { path: ':billId', element: <BillPage /> },
            ],
          },
          {
            path: CART_PATH,
            children: [
              { index: true, element: <CartPage /> },
              { path: CHECKOUT_PATH, element: <CheckoutPage /> },
            ],
          },
          {
            path: COUPON_PATH,
            element: <CouponPage />,
          },
          {
            path: DEPOSIT_PATH,
            element: <DepositPage />,
          },
          {
            path: COLLECTION_PATH,
            element: <CollectionPage />,
          },
          {
            path: NOTIFY_PATH,
            element: <NotifyPage />,
          },
          {
            path: ORDER_PATH,
            key: ORDER_PATH,
            children: [
              { index: true, element: <OrderListPage /> },
              { path: ':orderId', element: <OrderPage /> },
            ],
          },
          {
            path: '*',
            element: <Error data={{ code: errorStatus.ERR_404 }} />,
          },
        ],
      },
      {
        path: '*',
        element: <Error data={{ code: errorStatus.ERR_404 }} />,
      },
    ],
  },
];
