import { createSlice } from '@reduxjs/toolkit';

const billSlice = createSlice({
  name: 'billPage/billOne',
  initialState: {
    bill: undefined,
  },
  reducers: {
    getBill: (state, action) => {
      if (!!action.payload) {
        return {
          ...state,
          bill: action.payload,
        };
      }
    },
  },
});

export const { getBill } = billSlice.actions;

export default billSlice.reducer;
