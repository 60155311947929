export const regex = /^[\u4e00-\u9fa5]{2,6}$/;

export const regEmail = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const regSocialNumber = /^[A-Z]{1}[1-2]{1}\d{8}$/; // 台灣身分證
export const regTwMobile = /^09(\d{2}-?)([0-9]{3}-?)([0-9]{3})$/; // 台灣手機號碼
export const regTwPhone = /(^0[2-9]{1,4}-?)([0-9]{7,8})(?:#([0-9]+))?$/; // 台灣市話號碼 (含區碼)
export const regNoAreaCodeTwPhone = /([0-9]{7,8})(?:#([0-9]+))?$/; // 台灣市話號碼 (不含區碼)
export const regTwPostCode = /^(\d{5}|\d{3})?$/; // 台灣郵遞區號
export const regTwAreaCode = /^0[2-9]{1,4}$/; // 台灣區域號碼, 因有 0826 烏坵, 0836 馬祖, 所以增為 4 碼
export const regTwCityCall = /^09(\d{2}-?)([0-9]{3}-?)([0-9]{3})$|(^0[2-8]{1,2}-?)([0-9]{7,8})(?:#([0-9]+))?$/; // 台灣手機號碼 + 台灣市話號碼(含區碼)
export const regTwTaxId = /^\d{8}$/; // 台灣統編
export const floorType = 'floor';
export const roundType = 'round';
export const ceilType = 'ceil';
export const timeZoneType = {
  zhTw: 'Asia/Taipei',
}
export const dateTimeType = {
  days: 'days',
  months: 'months',
  years: 'years',
  hours: 'hours',
  minutes: 'minutes',
  seconds: 'seconds',
}
export const arithmeticType = ['add', 'subtract'];
export const dateCompareType = {
  isAfter: 'isAfter',
  isBefore: 'isBefore',
  isSameOrAfter: 'isSameOrAfter',
  isSameOrBefore: 'isSameOrBefore',
  isBetween: 'isBetween',
}
export const validateType = {
  chinese: 'chinese',
  normal: 'normal',
  email: 'email',
  socialNum: 'socialNum',
  twMobile: 'twMobile',
  twPhone: 'twPhone',
  twNoAreaCodePhone: 'twNoAreaCodePhone',
  twPostCode: 'twPostCode',
  twAreaCode: 'twAreaCode',
  twCityCall: 'twCityCall',
  twTaxId: 'twTaxId',
}

