export const ROOT_PATH = 'root';
export const BILL_PATH = 'bill';
export const BILL_LIST_PATH = 'bill-list';
export const CART_PATH = 'cart';
export const CART_PREVIEW_PATH = 'cart-preview';
export const CHECKOUT_PATH = 'checkout';
export const COUPON_PATH = 'coupon';
export const DEPOSIT_PATH = 'deposit';
export const COLLECTION_PATH = 'collection';
export const NOTIFY_PATH = 'notify';
export const ORDER_PATH = 'order';
export const ORDER_LIST_PATH = 'order-list';
export const SEARCH_PATH = 'search';
export const SHOP_PATH = 'shop';
