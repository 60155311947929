import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cartPage/cart',
  initialState: {
    cart: {},
    sheet: {},
  },
  reducers: {
    getCartItems: (state, action) => {
      if (!!action.payload) {
        return {
          ...state,
          cart: action.payload?.cart,
          sheet: action.payload?.sheet,
        };
      }
    },
    confirmChangedItemsToClear: state => {
      return {
        ...state,
        cart: { ...state.cart, item_compare_reports: [] },
      };
    },
  },
});

export const { getCartItems, confirmChangedItemsToClear } = cartSlice.actions;

export default cartSlice.reducer;
