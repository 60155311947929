import _ from 'lodash';
import React from 'react';
import { Button as BtnAntd } from 'antd';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

//#region
const primaryDisable = css`
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    background-color: var(--sheeta-disabled);
    color: var(--sheeta--basic-100);
  }
`;
const secondaryDisable = css`
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    color: var(--sheeta-disabled);
    border: 1px solid var(--sheeta-disabled);
  }
`;
const tertiaryDisable = css`
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    background-color: transparent;
    color: var(--sheeta-disabled);
  }
`;
const textDisable = css`
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    color: var(--sheeta-disabled);
  }
`;

const StyledButton = styled(BtnAntd)`
  color: var(--sheeta--basic-100);
  border: 0;
  font-weight: bold;
  ${props => props.width && `width: ${props.width};`};
  ${props => props.height && `width: ${props.height};`};
  padding: 0 18.5px;
  box-shadow: none;

  &::before {
    background: inherit;
  }

  &.btn-extra-large {
    height: 45px;
    font-size: 16px;
    border-radius: 40px;
    padding: ${props => (props.doublepadding ? '0 56px' : '0 28px')};
  }
  &.btn-large {
    padding: ${props => (props.doublepadding ? '0 50px' : '0 25px')};
  }
  &.btn-middle {
    padding: ${props => (props.doublepadding ? '0 37px' : '0 18.5px')};
  }
  &.btn-small {
    padding: ${props => (props.doublepadding ? '0 24px' : '0 12px')};
  }
  &.ant-btn-loading {
    opacity: 0.35;
  }

  &.btn-type-primary {
    background-color: var(--sheeta--primary-500);
    color: var(--sheeta--basic-100);
    &:hover {
      background-color: var(--sheeta--primary-400);
    }
    &:focus {
      background-color: var(--sheeta--primary-600);
    }
    &:active {
      background-color: var(--sheeta--primary-700);
    }
    @media (pointer: coarse), (hover: none) {
      &:hover {
        background-color: var(--sheeta--primary-500);
      }
    }
    ${primaryDisable}
  }
  &.btn-type-primary-basic {
    background: var(--sheeta--basic-600);
    color: var(--sheeta--basic-100);
    &:hover {
      background: var(--sheeta--basic-500);
    }
    &:focus {
      background: var(--sheeta--basic-700);
    }
    &:active {
      background: var(--sheeta--basic-800);
    }
    @media (pointer: coarse), (hover: none) {
      &:hover {
        background: var(--sheeta--basic-600);
      }
    }
    ${primaryDisable}
  }
  &.btn-type-primary-danger {
    background: var(--sheeta--danger-500);
    color: var(--sheeta--basic-100);
    &:hover {
      background: var(--sheeta--danger-400);
    }
    &:focus {
      background: var(--sheeta--danger-600);
    }
    &:active {
      background: var(--sheeta--danger-700);
    }
    @media (pointer: coarse), (hover: none) {
      &:hover {
        background: var(--sheeta--danger-500);
      }
    }
    ${primaryDisable}
  }
  &.btn-type-primary-warning {
    background: var(--warning-500);
    color: var(--sheeta--basic-100);
    &:hover {
      background: var(--warning-400);
    }
    &:focus {
      background: var(--warning-600);
    }
    &:active {
      background: var(--warning-700);
    }
    @media (pointer: coarse), (hover: none) {
      &:hover {
        background: var(--warning-500);
      }
    }
    ${primaryDisable}
  }

  &.btn-type-secondary-primary {
    background-color: transparent;
    color: var(--sheeta--primary-500);
    border: solid 1px var(--sheeta--primary-500);
    &:hover {
      color: var(--sheeta--primary-400);
      border: solid 1px var(--sheeta--primary-400);
    }
    &:focus {
      color: var(--sheeta--primary-600);
      border: solid 1px var(--sheeta--primary-600);
    }
    &:active {
      color: var(--sheeta--primary-700);
      border: solid 1px var(--sheeta--primary-700);
    }
    @media (pointer: coarse), (hover: none) {
      &:hover {
        color: var(--sheeta--primary-500);
        border: solid 1px var(--sheeta--primary-500);
      }
    }
    ${secondaryDisable};
  }
  &.btn-type-secondary-basic {
    background-color: transparent;
    color: var(--sheeta--basic-600);
    border: solid 1px var(--sheeta--basic-600);
    &:hover {
      border: solid 1px var(--sheeta--basic-500);
      color: var(--sheeta--basic-500);
    }
    &:focus {
      border: solid 1px var(--sheeta--basic-700);
      color: var(--sheeta--basic-700);
    }
    &:active {
      border: solid 1px var(--sheeta--basic-800);
      color: var(--sheeta--basic-800);
    }
    @media (pointer: coarse), (hover: none) {
      &:hover {
        color: var(--sheeta--basic-600);
        border: solid 1px var(--sheeta--basic-600);
      }
    }
    ${secondaryDisable};
  }
  &.btn-type-secondary-danger {
    background-color: transparent;
    color: var(--sheeta--danger-500);
    border: solid 1px var(--sheeta--danger-500);
    &:hover {
      color: var(--sheeta--danger-400);
      border: solid 1px var(--sheeta--danger-400);
    }
    &:focus {
      color: var(--sheeta--danger-600);
      border: solid 1px var(--sheeta--danger-600);
    }
    &:active {
      color: var(--sheeta--danger-700);
      border: solid 1px var(--sheeta--danger-700);
    }
    @media (pointer: coarse), (hover: none) {
      &:hover {
        color: var(--sheeta--danger-500);
        border: solid 1px var(--sheeta--danger-500);
      }
    }
    ${secondaryDisable};
  }
  &.btn-type-secondary-warning {
    background-color: transparent;
    color: var(--warning-500);
    border: solid 1px var(--warning-500);
    &:hover {
      color: var(--warning-400);
      border: solid 1px var(--warning-400);
    }
    &:focus {
      color: var(--warning-600);
      border: solid 1px var(--warning-600);
    }
    &:active {
      color: var(--warning-700);
      border: solid 1px var(--warning-700);
    }
    @media (pointer: coarse), (hover: none) {
      &:hover {
        color: var(--warning-500);
        border: solid 1px var(--warning-500);
      }
    }
    ${secondaryDisable};
  }

  &.ant-btn-tertiary-primary {
    background-color: transparent;
    color: var(--sheeta--primary-500);
    border: none;
    &:hover {
      background-color: var(--sheeta--primary-100);
    }
    &:focus {
      background-color: var(--sheeta--primary-200);
    }
    &:active {
      background-color: var(--sheeta--primary-300);
    }
    @media (pointer: coarse), (hover: none) {
      &:hover {
        color: var(--sheeta--primary-500);
      }
    }
    ${tertiaryDisable}
  }
  &.ant-btn-tertiary-basic {
    background-color: transparent;
    color: var(--sheeta--basic-600);
    border: none;
    &:hover {
      background-color: var(--sheeta--basic-200);
    }
    &:focus {
      background-color: var(--sheeta--basic-300);
    }
    &:active {
      background-color: var(--sheeta--basic-400);
    }
    @media (pointer: coarse), (hover: none) {
      &:hover {
        color: var(--sheeta--basic-600);
      }
    }
    ${tertiaryDisable}
  }
  &.ant-btn-tertiary-danger {
    background-color: transparent;
    color: var(--sheeta--danger-500);
    border: none;
    &:hover {
      background-color: var(--sheeta--danger-100);
    }
    &:focus {
      background-color: var(--sheeta--danger-200);
    }
    &:active {
      background-color: var(--sheeta--danger-300);
    }
    @media (pointer: coarse), (hover: none) {
      &:hover {
        color: var(--sheeta--danger-500);
      }
    }
    ${tertiaryDisable}
  }
  &.ant-btn-tertiary-warning {
    background-color: transparent;
    color: var(--warning-500);
    border: none;
    &:hover {
      background-color: var(--warning-100);
    }
    &:focus {
      background-color: var(--warning-200);
    }
    &:active {
      background-color: var(--warning-300);
    }
    @media (pointer: coarse), (hover: none) {
      &:hover {
        background-color: transparent;
      }
    }
    ${tertiaryDisable}
  }

  &.btn-type-text-primary {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    color: var(--sheeta--primary-500);
    &:hover {
      color: var(--sheeta--primary-400);
    }
    &:focus {
      color: var(--sheeta--primary-600);
    }
    &:active {
      color: var(--sheeta--primary-700);
    }
    @media (pointer: coarse), (hover: none) {
      &:hover {
        color: var(--sheeta--primary-500);
      }
    }
    ${textDisable}
  }
  &.btn-type-text-basic {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    color: var(--sheeta--basic-600);
    &:hover {
      color: var(--sheeta--basic-500);
    }
    &:focus {
      color: var(--sheeta--basic-700);
    }
    &:active {
      color: var(--sheeta--basic-800);
    }
    @media (pointer: coarse), (hover: none) {
      &:hover {
        color: var(--sheeta--basic-600);
      }
    }
    ${textDisable}
  }
  &.btn-type-text-danger {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    color: var(--sheeta--danger-500);
    &:hover {
      color: var(--sheeta--danger-400);
    }
    &:focus {
      color: var(--sheeta--danger-600);
    }
    &:active {
      color: var(--sheeta--danger-700);
    }
    @media (pointer: coarse), (hover: none) {
      &:hover {
        color: var(--sheeta--danger-500);
      }
    }
    ${textDisable}
  }
  &.btn-type-text-warning {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    color: var(--warning-500);
    &:hover {
      color: var(--warning-400);
    }
    &:focus {
      color: var(--warning-600);
    }
    &:active {
      color: var(--warning-700);
    }
    @media (pointer: coarse), (hover: none) {
      &:hover {
        color: var(--warning-500);
      }
    }
    ${textDisable}
  }
`;
//#endregion

const Button = ({
  buttonText,
  size,
  type,
  isLoading,
  isBlock,
  onClick,
  isDisabled,
  width,
  height,
  doublePadding,
  icon,
  className,
  ...props
}) => {
  return (
    <StyledButton
      shape="round"
      type={type}
      size={size}
      block={isBlock}
      disabled={isDisabled}
      onClick={onClick}
      loading={isLoading}
      className={`btn-type-${type} btn-${size} ${!_.isEmpty(className) ? className : ''}`}
      width={width}
      height={height}
      icon={icon}
      doublepadding={doublePadding ? 'doublepadding' : undefined}
      {...props}
    >
      {buttonText}
    </StyledButton>
  );
};

export default Button;

Button.propTypes = {
  buttonText: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.oneOf([
    'primary',
    'primary-basic',
    'primary-danger',
    'primary-warning',
    'secondary-primary',
    'secondary-basic',
    'secondary-danger',
    'secondary-warning',
    'tertiary-primary',
    'tertiary-basic',
    'tertiary-danger',
    'text-primary',
    'text-basic',
    'text-danger',
    'text-warning',
  ]),
  isLoading: PropTypes.bool,
  isBlock: PropTypes.bool,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  icon: PropTypes.element,
  doublePadding: PropTypes.bool,
};

Button.defaultProps = {
  buttonText: '確認',
  size: 'middle',
  type: 'primary',
  isLoading: false,
  isBlock: false,
  isDisabled: false,
  width: '',
  height: undefined,
  onClick: () => {},
  icon: undefined,
  doublePadding: false,
};
