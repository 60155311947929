import React from 'react';
import styled, { css } from 'styled-components';
import { Checkbox, Radio } from 'antd';
import PropTypes from 'prop-types';

const Container = styled.div`
  width: auto;
  height: auto;
  min-width: 148px;
  max-width: 309px;
  max-height: 332px;
  border-radius: 8px;
`;
const content = css`
  &.ant-checkbox-group,
  &.ant-radio-group {
    display: grid;
    grid-template-columns: 1fr;
    padding: 4px;
  }

  .ant-checkbox-group-item,
  .ant-radio-wrapper {
    margin-right: 0;
    padding: 6px 12px;
    border-radius: 8px;
    color: ${props => props.text};

    &:hover {
      cursor: pointer;
      background: var(--basic-200);
    }
  }

  .ant-checkbox-group-item:not(:first-child),
  .ant-radio-wrapper:not(:first-child) {
    margin-top: 4px;
  }

  .ant-checkbox-group-item:has(.ant-checkbox-checked),
  .ant-radio-wrapper.ant-radio-wrapper-checked {
    background: var(--basic-200);
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--border-color);
  }

  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-radio-checked .ant-radio-inner::after {
    background-color: ${props => props.box};
  }
`;
const CheckboxContent = styled(Checkbox.Group)`
  ${content}
`;
const RadioContent = styled(Radio.Group)`
  ${content}
`;

const DropdownFilter = ({ type, options, value, onChange, itemColor, textColor }) => {
  switch (type) {
    case 'radio':
      return (
        <Container>
          <RadioContent
            box={itemColor}
            text={textColor}
            options={options}
            value={value}
            onChange={e => onChange(e.target.value)}
          />
          ;
        </Container>
      );
    case 'checkbox':
      return (
        <Container>
          <CheckboxContent box={itemColor} text={textColor} options={options} value={value} onChange={onChange} />
        </Container>
      );
    default:
      return null;
  }
};

export default DropdownFilter;

DropdownFilter.propTypes = {
  type: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  itemColor: PropTypes.string,
  textColor: PropTypes.string,
};

DropdownFilter.defaultProps = {
  type: 'checkbox',
  options: [],
  value: [],
  onChange: () => {},
  itemColor: 'var(--basic-800)',
  textColor: 'var(--basic-800)',
};
