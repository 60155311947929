import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { SHOP_PATH, CART_PATH, CART_PREVIEW_PATH } from '@/constants/routePath';
import CloseBtn from '@/components/Button/CloseBtn';
import { Thumbnail, ItemNameSubTitle } from '@/components/Table/View/styles';
import ImageWrapper from '@/components/ImageWrapper';
import Empty from '@/components/Empty';
import EmptyImage from '@/images/icon-empty-cart.svg';
import { convertCurrencyFormat } from '@/utils/dentall-components/convert';
import Button from '@/components/Button/Button';
import Alert from '@/components/Alert';

const Container = styled.div`
  width: 400px;
  min-height: 242px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  padding: 24px 8px 24px 24px;
  box-sizing: border-box;
  .cart-alert-message-wrapper {
    margin-bottom: 16px;
    .ant-alert-message {
      color: var(--basic-600);
    }
  }
  .cart-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 240px;
    padding-right: 16px;
    box-sizing: border-box;
    overflow-y: scroll;
  }
  .cart-total-price-wrapper {
    margin-bottom: 17px;
  }
  .cart-dividing {
    margin: 24px 0 16px 0;
    border-bottom: solid 1px var(--border-color);
  }
  .cart-item-wrapper {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .cart-item-space-between {
    display: flex;
    justify-content: space-between;
  }
  .cart-item-margin {
    margin-right: 16px;
  }
  .card-control-wrapper {
    width: 100%;
    padding-right: 16px;
  }
`;

const ThumbnailStyled = styled(Thumbnail)`
  width: 64px;
  height: 64px;
  img {
    width: 100%;
    height: 100%;
  }
`;
const CloseBtnWrapper = styled.div`
  height: fit-content;
  position: relative;
  top: 24px;
`;
const ProductNameWrapper = styled.div`
  width: 100%;
`;
const ItemNameStyled = styled(ItemNameSubTitle)`
  .item-margin {
    margin-bottom: 4px;
  }

  .item-count {
    color: var(--basic-600);
  }
  .item-total-name {
    color: var(--basic-500);
  }
  .item-total-name {
    font-weight: 600;
  }
  .item-count-price,
  .item-total-price {
    color: var(--basic-600);
    font-weight: 600;
  }
  .item-total-price {
    font-size: 16px;
  }
`;

const EmptyContainer = styled.div`
  width: 400px;
  height: 152px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  box-sizing: border-box;
  img {
    width: 72px;
    height: 72px;
  }
`;

const reSelector = createSelector(
  state => ({
    ...state.homePageReducer.ws,
    ...state.cartPageReducer.cart,
  }),
  ({ cart, sheet, ws, member }) => ({
    cart,
    sheet,
    ws,
    member,
  }),
);

const CartPreview = ({ removeFromCartTrigger }) => {
  const { cart, sheet, ws, member } = useSelector(reSelector);
  const navigate = useNavigate();
  const { formatMessage: f } = useIntl();
  const isShowAlertMessage = !_.isEmpty(cart?.item_compare_reports);
  function handleNavigateToShop(shopId) {
    navigate(`/${SHOP_PATH}/${shopId}`);
  }
  function handleRemoveFromCart(itemId) {
    removeFromCartTrigger({
      workspace_id: ws?._id,
      member_id: member?._id,
      item_id: itemId,
    });
  }
  function renderCartEmptyView() {
    return <Empty type={CART_PREVIEW_PATH} image={EmptyImage} description={f({ id: 'cart.list.empty' })} />;
  }
  function renderCartAlertView() {
    return (
      <div className="cart-alert-message-wrapper">
        <Alert message={f({ id: 'message.cart.product.adjustment' })} mode="frame" type="warning" showIcon></Alert>
      </div>
    );
  }
  function renderCartItemView() {
    const cartItemData = [...cart?.items]?.reverse();
    return cartItemData.map(item => {
      const product = item.product;
      return (
        <div className="cart-item-wrapper" key={item._id}>
          <CloseBtnWrapper className="cart-item-margin">
            <CloseBtn onClick={() => handleRemoveFromCart(item._id)} />
          </CloseBtnWrapper>
          <div className="cart-item-margin">
            <ThumbnailStyled onClick={() => handleNavigateToShop(product._id)}>
              <ImageWrapper thumbnail={product.thumbnail} alt={'item-thumbnail'} />
            </ThumbnailStyled>
          </div>
          <ProductNameWrapper>
            <ItemNameStyled
              lineClamp={{ title: 2, subtitle: 1 }}
              color={'var(--basic-600)'}
              hoverStyle={{ color: 'var(--primary-500)' }}
            >
              <div className="item-name" onClick={() => handleNavigateToShop(product._id)}>
                {product.name}
              </div>
              <div className="item-subtitle item-margin">{`${item.subtitle}`}</div>
              <div className="cart-item-space-between">
                <div className="item-count">{`x${item.package_quantity}`}</div>
                <div className="item-count-price">{`${convertCurrencyFormat(
                  item?.package_total_price || 0,
                  'NT$',
                )}`}</div>
              </div>
            </ItemNameStyled>
          </ProductNameWrapper>
        </div>
      );
    });
  }
  function renderCartTotalView() {
    return (
      <div className="cart-total-price-wrapper ">
        <ItemNameStyled>
          <div className="cart-item-space-between">
            <div className="item-total-name">{f({ id: 'order.label.product.sum' })}</div>
            <div className="item-total-price">{`${convertCurrencyFormat(sheet.total || 0, 'NT$')}`}</div>
          </div>
        </ItemNameStyled>
      </div>
    );
  }

  return (
    <>
      {!_.isEmpty(cart.items) ? (
        <Container>
          <div className="cart-wrapper ">
            {isShowAlertMessage && renderCartAlertView()}
            {renderCartItemView()}
          </div>
          <div className="cart-dividing" />
          <div className="card-control-wrapper">
            {renderCartTotalView()}
            <Button block onClick={() => navigate(`${ws._id}/${CART_PATH}`)} buttonText={f({ id: 'button.go.cart' })} />
          </div>
        </Container>
      ) : (
        <EmptyContainer>{renderCartEmptyView()}</EmptyContainer>
      )}
    </>
  );
};

export default CartPreview;
