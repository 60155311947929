import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const depositSlice = createSlice({
  name: 'depositPage/deposit',
  initialState: {
    deposits: undefined,
    total: null,
    currentTotalLens: 0,
  },
  reducers: {
    getDeposits: (state, action) => {
      if (!!action.payload) {
        return {
          ...state,
          deposits: action.payload?.results,
          total: action.payload?.total,
          currentTotalLens: !_.isNil(action.payload?.results) ? action.payload?.results.length : state.currentTotalLens,
        };
      }
    },
  },
});

export const { getDeposits } = depositSlice.actions;

export default depositSlice.reducer;
