import _ from 'lodash';
import React from 'react';
import { Alert } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '@/components/Icon';
import SuccessCircleOutlined from '@/components/Icon/SuccessCircleOutlined';
import WarningCircleOutlined from '@/components/Icon/WarningCircleOutlined';
import InfoCircleOutlined from '@/components/Icon/InfoCircleOutlined';
import ErrorCircleOutlined from '@/components/Icon/ErrorCircleOutlined';
import CloseOutlined from '@/components/Icon/CloseOutlined';

//#region
const StyledAlert = styled(Alert)`
  ${props => props.mode === 'solid' && `border: none;`}
  align-items: center;
  .ant-alert-message,
  .ant-alert-action {
    font-size: 14px;
  }
  .ant-alert-icon {
    width: ${props => (!!props?.iconSize ? props.iconSize : '16')}px;
    height: ${props => (!!props?.iconSize ? props.iconSize : '16')}px;
  }
  .ant-alert-description {
    color: var(--sheeta--basic-800);
  }

  background-color: ${props => {
    switch (props.type) {
      case 'success':
        return props.mode === 'hollow' ? 'transparent' : 'var(--success-100)';
      case 'info':
        return props.mode === 'hollow' ? 'transparent' : 'var(--sheeta--primary-100)';
      case 'warning':
        return props.mode === 'hollow' ? 'transparent' : 'var(--warning-100)';
      case 'error':
        return props.mode === 'hollow' ? 'transparent' : 'var(--sheeta--danger-100)';
      case 'other':
        return props.mode === 'hollow' ? 'transparent' : props?.bgColor;
      default:
        return 'transparent';
    }
  }};
  border: ${props => {
    switch (props.type) {
      case 'success':
        return props.mode === 'solid' ? 'none' : '1px solid var(--success-500)';
      case 'info':
        return props.mode === 'solid' ? 'none' : '1px solid var(--sheeta--primary-500)';
      case 'warning':
        return props.mode === 'solid' ? 'none' : '1px solid var(--warning-500)';
      case 'error':
        return props.mode === 'solid' ? 'none' : '1px solid var(--sheeta--danger-500)';
      case 'other':
        return props.mode === 'solid' ? 'none' : `1px solid ${props?.bdColor}`;
      default:
        return 'none';
    }
  }};

  ${props =>
    !_.isEmpty(props.description) &&
    `
    .ant-alert-message {
        font-size: 18px;
        font-weight: 600;
    }
    .ant-alert-description {
        font-size: 14px;
    }
    .ant-alert-close-text {
        font-size: 14px;
        color: var(--sheeta--primary-500);
    }
    .ant-alert-close-text svg {
        width: 20px;
    }
`}
`;
//#endregion

const CustomizedAlert = ({ mode, message, type, actionElement, showIcon, closable, description, banner, ...props }) => {
  const generateIcon = type => {
    switch (type) {
      case 'success': {
        return props?.iconComponent ? (
          <Icon component={props?.iconComponent} color={props?.iconColor} size={props?.iconSize} />
        ) : (
          <SuccessCircleOutlined />
        );
      }
      case 'info': {
        return props?.iconComponent ? (
          <Icon component={props?.iconComponent} color={props?.iconColor} size={props?.iconSize} />
        ) : (
          <InfoCircleOutlined />
        );
      }
      case 'warning': {
        return props?.iconComponent ? (
          <Icon component={props?.iconComponent} color={props?.iconColor} size={props?.iconSize} />
        ) : (
          <WarningCircleOutlined />
        );
      }
      case 'error': {
        return props?.iconComponent ? (
          <Icon component={props?.iconComponent} color={props?.iconColor} size={props?.iconSize} />
        ) : (
          <ErrorCircleOutlined />
        );
      }
      case 'other': {
        return <Icon component={props?.iconComponent} color={props?.iconColor} />;
      }
      default: {
        return null;
      }
    }
  };
  return (
    <StyledAlert
      mode={mode}
      message={message}
      type={type}
      showIcon={showIcon}
      action={actionElement ?? null}
      description={description}
      banner={banner}
      closeText={
        !closable ? undefined : description ? <CloseOutlined /> : <CloseOutlined size={24} color="var(--basic-600)" />
      }
      icon={generateIcon(type)}
      {...props}
    />
  );
};

export default CustomizedAlert;

CustomizedAlert.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  type: PropTypes.string,
  showIcon: PropTypes.bool,
  actionElement: PropTypes.element,
  closable: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  banner: PropTypes.bool,
  mode: PropTypes.oneOf(['solid', 'frame', 'hollow']),
};

CustomizedAlert.defaultProps = {
  message: '',
  type: 'info',
  showIcon: false,
  actionElement: null,
  closable: false,
  description: '',
  banner: false,
  mode: 'solid',
};
