import _ from 'lodash';
import { helpfulLink, lineLink, wsUrl } from '@/constants/config';
import { KeypadContent, KeypadEmptyContent, KeypadTitle } from '@/components/Header/Navbar/Masthead/KeypadCard';
import CartPreview from '@/components/Cart/Preview';
import {
  BILL_PATH,
  CART_PATH,
  CHECKOUT_PATH,
  COUPON_PATH,
  DEPOSIT_PATH,
  COLLECTION_PATH,
  NOTIFY_PATH,
  ORDER_PATH,
  SHOP_PATH,
} from '@/constants/routePath';
import Icon from '@/components/Icon';
import { redirectHrefTo } from '@/utils/dentall-components/string';
import { ReactComponent as MedicalSuitcase } from '@/images/dentall-components/medical-suitcase.svg';
import { ReactComponent as Bell } from '@/images/dentall-components/bell.svg';
import { ReactComponent as FileText } from '@/images/dentall-components/file-text.svg';
import { ReactComponent as Book } from '@/images/dentall-components/book.svg';
import { ReactComponent as Gift } from '@/images/dentall-components/gift.svg';
import { ReactComponent as Money } from '@/images/dentall-components/money.svg';
import { ReactComponent as Search } from '@/images/dentall-components/search.svg';
import { ReactComponent as MessageSquare } from '@/images/dentall-components/message-square.svg';
import { ReactComponent as QuestionMarkCircle } from '@/images/dentall-components/question-mark-circle.svg';
import { ReactComponent as Heart } from '@/images/dentall-components/heart.svg';
import { ReactComponent as ShoppingCart } from '@/images/dentall-components/shopping-cart.svg';
import { ReactComponent as KeypadFill } from '@/images/dentall-components/keypad-fill.svg';
import { ReactComponent as Close } from '@/images/dentall-components/close.svg';
import { ReactComponent as Menu } from '@/images/dentall-components/menu.svg';
import { ReactComponent as Home } from '@/images/dentall-components/home.svg';

export const PERMISSION_PATH = [
  BILL_PATH,
  CART_PATH,
  COUPON_PATH,
  DEPOSIT_PATH,
  COLLECTION_PATH,
  NOTIFY_PATH,
  ORDER_PATH,
];

export const fixedPermissionRoute = ({ wsId = '', billId = '', orderId = '' }) => {
  return [
    { path: `/${wsId}/${BILL_PATH}` },
    { path: `/${wsId}/${BILL_PATH}/${billId}` },
    { path: `/${wsId}/${CART_PATH}` },
    { path: `/${wsId}/${CART_PATH}/${CHECKOUT_PATH}` },
    { path: `/${wsId}/${COUPON_PATH}` },
    { path: `/${wsId}/${DEPOSIT_PATH}` },
    { path: `/${wsId}/${COLLECTION_PATH}` },
    { path: `/${wsId}/${NOTIFY_PATH}` },
    { path: `/${wsId}/${ORDER_PATH}` },
    { path: `/${wsId}/${ORDER_PATH}/${orderId}` },
  ];
};
export const parseNoLayoutRoute = () => {
  return [`/`];
};
export const parseNoPageHeaderRoute = () => {
  return [`/`];
};
export const parsePermissionMenu = ({
  f,
  hasPermission = false,
  wsId,
  navigate,
  isMobile = false,
  setOpenCategoryKeys,
}) => {
  const iconSize = isMobile ? 24 : 14;
  const menu = [
    {
      key: `/${SHOP_PATH}`,
      label: f({ id: 'menu.shop' }),
      icon: <Icon component={MedicalSuitcase} size={iconSize} color={'var(--basic-600)'} />,
      path: `/${SHOP_PATH}`,
      onClick: () => {
        setOpenCategoryKeys([]);
        navigate(`/${SHOP_PATH}`);
      },
    },
    isMobile && {
      key: `${wsId}/${NOTIFY_PATH}`,
      label: f({ id: 'menu.notify' }),
      icon: <Icon component={Bell} size={iconSize} color={'var(--basic-600)'} />,
      path: `${wsId}/${NOTIFY_PATH}`,
      onClick: () => {
        setOpenCategoryKeys([]);
        navigate(`${wsId}/${NOTIFY_PATH}`);
      },
    },
    !isMobile && {
      key: 'border',
      bordered: true,
    },
    {
      key: `${wsId}/${ORDER_PATH}`,
      label: f({ id: 'menu.order' }),
      icon: <Icon component={FileText} size={iconSize} color={'var(--basic-600)'} />,
      path: `${wsId}/${ORDER_PATH}`,
      onClick: () => {
        setOpenCategoryKeys([]);
        navigate(`${wsId}/${ORDER_PATH}`);
      },
    },
    {
      key: `${wsId}/${BILL_PATH}`,
      label: f({ id: 'menu.bill' }),
      icon: <Icon component={Book} size={iconSize} color={'var(--basic-600)'} />,
      path: `${wsId}/${BILL_PATH}`,
      onClick: () => {
        setOpenCategoryKeys([]);
        navigate(`${wsId}/${BILL_PATH}`);
      },
    },
    {
      key: `${wsId}/${COUPON_PATH}`,
      label: f({ id: 'menu.coupon' }),
      icon: <Icon component={Gift} size={iconSize} color={'var(--basic-600)'} />,
      path: `${wsId}/${COUPON_PATH}`,
      onClick: () => {
        setOpenCategoryKeys([]);
        navigate(`${wsId}/${COUPON_PATH}`);
      },
    },
    {
      key: `${wsId}/${DEPOSIT_PATH}`,
      label: f({ id: 'menu.deposit' }),
      icon: <Icon component={Money} size={iconSize} color={'var(--basic-600)'} />,
      path: `${wsId}/${DEPOSIT_PATH}`,
      onClick: () => {
        setOpenCategoryKeys([]);
        navigate(`${wsId}/${DEPOSIT_PATH}`);
      },
    },
  ];
  if (!hasPermission) return [menu[0]];
  return menu.filter(Boolean);
};

export const parseMastheadMenu = ({
  f,
  currentUser,
  hasPermission,
  wsId,
  wsName,
  wsList,
  services,
  isTablet,
  isTabletAndMobile,
  isMobile,
  deviceWidth,
  align = {},
  navigate,
  cart,
  setOpenCategoryKeys,
  setSearchDrawerVisible,
  removeFromCartTrigger,
  alertModalInfo,
}) => {
  return [
    {
      visible: isTablet,
      iconButtonProps: {
        size: 'large',
        iconSize: 24,
        shape: 'circle',
        type: 'tertiary-basic',
        iconElement: Search,
        onClick: () => setSearchDrawerVisible(true),
      },
    },
    {
      visible: true,
      displayTooltip: true,
      tooltipProps: {
        title: f({ id: 'tooltip.contact.angel' }),
        placement: 'bottom',
        trigger: 'hover',
        justify: 'center',
      },
      iconButtonProps: {
        size: 'large',
        iconSize: 24,
        shape: 'circle',
        type: 'tertiary-basic',
        iconElement: MessageSquare,
        onClick: () => window.open(lineLink, '_blank', 'noopener'),
      },
    },
    {
      visible: true,
      displayTooltip: true,
      tooltipProps: {
        title: f({ id: 'tooltip.common.question' }),
        placement: 'bottom',
        trigger: 'hover',
        justify: 'center',
      },
      iconButtonProps: {
        size: 'large',
        iconSize: 24,
        shape: 'circle',
        type: 'tertiary-basic',
        iconElement: QuestionMarkCircle,
        onClick: () => window.open(helpfulLink, '_blank', 'noopener'),
      },
    },
    {
      visible: hasPermission && !isMobile,
      displayTooltip: true,
      tooltipProps: {
        title: f({ id: 'tooltip.notify.center' }),
        placement: 'bottom',
        trigger: 'hover',
        justify: 'center',
      },
      iconButtonProps: {
        size: 'large',
        iconSize: 24,
        shape: 'circle',
        type: 'tertiary-basic',
        iconElement: Bell,
        onClick: () => {
          setOpenCategoryKeys([]);
          navigate(`${wsId}/${NOTIFY_PATH}`);
        },
      },
    },
    {
      visible: hasPermission && !isMobile,
      displayTooltip: true,
      tooltipProps: {
        title: f({ id: 'tooltip.collection.list' }),
        placement: 'bottom',
        trigger: 'hover',
        justify: 'center',
      },
      iconButtonProps: {
        size: 'large',
        iconSize: 24,
        shape: 'circle',
        type: 'tertiary-basic',
        iconElement: Heart,
        onClick: () => {
          setOpenCategoryKeys([]);
          navigate(`${wsId}/${COLLECTION_PATH}`);
        },
      },
    },
    {
      visible: hasPermission && !isMobile,
      displayPopover: true,
      popoverProps: {
        overlayStyle: {
          width: 'fit-content',
        },
        align: {
          offset: [120, 20],
          placement: 'bottomRight',
        },
        content: <CartPreview removeFromCartTrigger={removeFromCartTrigger} />,
        trigger: alertModalInfo.title || isTablet ? [''] : ['hover'],
        overlayClassName: 'comment-popover-overlay navbar__userInfo-popover masthead-popover',
        placement: !isTabletAndMobile ? 'bottomRight' : 'bottom',
      },
      displayBadge: true,
      badgeProps: {
        size: 'small',
        count: !_.isNil(cart) ? cart?.items?.length : 0,
        offset: [-7, 10],
        color: 'var(--primary-500)',
        onClick: () => {
          setOpenCategoryKeys([]);
          navigate(`${wsId}/${CART_PATH}`);
        },
      },
      iconButtonProps: {
        size: 'large',
        iconSize: 24,
        shape: 'circle',
        type: 'tertiary-basic',
        iconElement: ShoppingCart,
        onClick: () => {
          setOpenCategoryKeys([]);
          navigate(`${wsId}/${CART_PATH}`);
        },
      },
    },
    {
      verticalLine: true,
    },
    {
      visible: !_.isEmpty(currentUser),
      displayTooltip: !isTabletAndMobile,
      tooltipProps: {
        title: f({ id: 'tooltip.switch.service' }),
        placement: 'bottom',
        trigger: 'hover',
        justify: 'center',
      },
      displayPopover: true,
      popoverProps: {
        overlayStyle: {
          width: `${deviceWidth * 0.9}px`,
          maxWidth: !_.isEmpty(wsList) ? '360px' : '300px',
        },
        align,
        title: !_.isEmpty(wsList) ? (
          <KeypadTitle wsId={wsId} wsName={wsName} switchText={f({ id: 'masthead.keypad.switchClinic' })} />
        ) : null,
        content: !_.isEmpty(wsList) ? (
          <KeypadContent services={services} />
        ) : (
          <KeypadEmptyContent
            title={f({ id: 'masthead.keypad.empty.title' })}
            btnText={f({ id: 'button.create.rightNow' })}
            clickHandler={() => redirectHrefTo(wsUrl)}
          />
        ),
        trigger: ['click'],
        overlayClassName: 'navbar__userInfo-popover masthead-popover',
        placement: !isTabletAndMobile ? 'bottomRight' : 'bottom',
      },
      iconButtonProps: {
        iconSize: 24,
        shape: 'circle',
        type: 'tertiary-basic',
        iconElement: KeypadFill,
        onClick: () => {},
      },
    },
  ];
};

export const parseFixedTabMenu = ({
  wsId,
  hasPermission,
  drawerVisible,
  setVisible,
  navigate,
  setSearchDrawerVisible,
  setCategoryVisible,
  cart,
}) => {
  return [
    {
      visible: true,
      iconButtonProps: {
        iconSize: 24,
        shape: 'round',
        type: 'tertiary-basic',
        iconElement: drawerVisible ? Close : Menu,
        onClick: () => {
          setVisible(!drawerVisible);
          setCategoryVisible(false);
          setSearchDrawerVisible(false);
        },
      },
    },
    {
      visible: true,
      iconButtonProps: {
        iconSize: 24,
        shape: 'round',
        type: 'tertiary-basic',
        iconElement: Home,
        onClick: () => {
          navigate('/');
          setVisible(false);
          setCategoryVisible(false);
          setSearchDrawerVisible(false);
        },
      },
    },
    {
      visible: true,
      iconButtonProps: {
        iconSize: 24,
        shape: 'round',
        type: 'tertiary-basic',
        iconElement: Search,
        onClick: () => {
          setSearchDrawerVisible(true);
          setVisible(false);
          setCategoryVisible(false);
        },
      },
    },
    {
      visible: hasPermission,
      iconButtonProps: {
        iconSize: 24,
        shape: 'round',
        type: 'tertiary-basic',
        iconElement: Heart,
        onClick: () => {
          navigate(`${wsId}/${COLLECTION_PATH}`);
          setVisible(false);
          setCategoryVisible(false);
          setSearchDrawerVisible(false);
        },
      },
    },
    {
      visible: hasPermission,
      displayBadge: true,
      badgeProps: {
        size: 'small',
        count: !_.isNil(cart) ? cart?.items?.length : 0,
        offset: [-15, 7],
        color: 'var(--primary-500)',
      },
      iconButtonProps: {
        iconSize: 24,
        shape: 'round',
        type: 'tertiary-basic',
        iconElement: ShoppingCart,
        onClick: () => {
          navigate(`${wsId}/${CART_PATH}`);
          setVisible(false);
          setCategoryVisible(false);
          setSearchDrawerVisible(false);
        },
      },
    },
  ];
};
