import _ from 'lodash';
import { ceilType, floorType, roundType } from './constants';

export const convertCurrencyFormat = (num = 0, currency = '', options = { isAddMinusSymbol: false, decimal: false, decimalType: '' }) => {
  if (num === 0 || !num) return `${currency}0`;
  let tempNum = Number(num);
  let tempCurrency = currency;
  if (options?.isAddMinusSymbol && Number(num) < 0) {
    tempNum = Math.abs(num);
    tempCurrency = `-${currency}`;
  }

  if (options?.decimal && !Number.isInteger(tempNum)) {
    switch (options?.decimalType) {
      case 'floor':
        tempNum = Math.floor(tempNum);
        return `${tempCurrency}${tempNum.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
      case 'round':
        tempNum = Math.round(tempNum);
        return `${tempCurrency}${tempNum.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
      case 'ceil':
        tempNum = Math.ceil(tempNum);
        return `${tempCurrency}${tempNum.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
      default:
        const splitNum = tempNum
          .toString()
          .split('.')
          .map(n => Number(n));
        return `${tempCurrency}${splitNum[0].toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}.${splitNum[1]}`;
    }
  } else {
    return `${tempCurrency}${tempNum.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  }
};

export const convertNumber = ({ num, type = '', isFixed = false, fixed = 0 }) => {
  if (num === 0 || !num || !_.isNumber(num)) return 0;

  num = isFixed ? Number(num).toFixed(fixed) : num;
  const length = num.toString().split('.')[1].length;
  const multi = Math.pow(10, length);

  switch (type) {
    case floorType:
      num = Math.floor(num);
      break;
    case roundType:
      num = isFixed ? Math.round(num * multi) / multi : Math.round(num);
      break;
    case ceilType:
      num = Math.ceil(num);
      break;
    default:
      break;
  }

  return num;
};

export const convertArrToObj = (array, key) => {
  if (_.isEmpty(array)) return {};
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const convertBoolToStr = (value = false, type = 0) => {
  switch (type) {
    case 1:
      return value ? 'Y' : 'N';
    case 2:
      return value ? '有' : '無';
    case 3:
      return value ? 'Yes' : 'No';
    case 0:
    default:
      return value ? '是' : '否';
  }
};
