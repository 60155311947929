import React from 'react';
import { Table as AntTable } from 'antd';
import styled from 'styled-components';
import FilterIcon from '@/components/Table/FilterIcon';
import DropdownFilter from '@/components/Table/DropdownFilter';

// region
const Container = styled.div`
  .ant-table-thead {
    height: 54px;

    .ant-table-cell {
      padding: 0 16px;
      background: ${props => (!!props.bgColor ? props.bgColor : 'transparent')};
      font-weight: 600;
      font-size: 14px;
      color: ${props => (!!props.thColor ? props.thColor : 'var(--basic-500)')};

      ::before {
        background-color: ${props => (!!props.bdColor ? props.bdColor : 'transparent')} !important;
      }
    }
    .ant-dropdown-trigger:hover {
      background: transparent;
    }
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: var(--basic-200);
  }
  .notice-description {
    .ant-table-container {
      border-radius: 8px;
    }
    .ant-table-tbody > tr > td {
      border-color: var(--border-color) !important;
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr:first-child {
      > td {
        &:first-child {
          border-radius: 8px 0 0 0;
        }
        &:last-child {
          border-radius: 0 8px 0 0;
        }
      }
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr:last-child {
      border-radius: 0 0 0 8px;
      > td {
        &:first-child {
          border-radius: 0 0 0 8px;
        }
        &:last-child {
          border-radius: 0 0 8px 0;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-cell:has(.notice-desc-title) {
        background: var(--basic-200);
        font-weight: initial;
        font-size: 14px;
      }
    }
  }
`;
// endregion

const Table = ({ bgColor, thColor, bdColor, loading, dataSource, columns, onTableChange, ...props }) => {
  return (
    <Container bgColor={bgColor} thColor={thColor} bdColor={bdColor}>
      <AntTable loading={loading} dataSource={dataSource} columns={columns} onChange={onTableChange} {...props} />
    </Container>
  );
};

Table.FilterIcon = FilterIcon;
Table.DropdownFilter = DropdownFilter;

export default Table;
