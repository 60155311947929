import _ from 'lodash';
import React from 'react';
import IconAntd from '@ant-design/icons';
import styled from 'styled-components';

const StyledIcon = styled(IconAntd)`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: currentColor;
    g {
      fill: currentColor;
    }
    path {
      fill: currentColor;
    }
  }
  font-size: ${props => (props.size ? `${props.size}px` : '16px')} !important;
  color: ${props => (props.color ? `${props.color}` : 'currentColor')};
  ${props => props.background && `background: ${props.background};`}
`;

const Icon = ({ ...props }) => {
  if (_.isEmpty(props?.component)) return null;
  return <StyledIcon {...props} />;
};

export default Icon;
