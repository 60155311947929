import _ from 'lodash';
import styled from 'styled-components';
import Table from '@/components/Table';

export const Thumbnail = styled.div`
  height: 70px;
  img {
    border: solid 1px var(--border-color);
    border-radius: 4px;
    width: 70px;
    height: 70px;
    object-fit: contain;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const ItemName = styled.div`
  position: relative;
  .item-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${({ lineClamp }) => (lineClamp?.title ? lineClamp?.title : 'initial')};
    font-size: 14px;
    font-weight: 600;
    color: ${({ color }) => (color ? color : 'var(--basic-800)')};
  }
  &:hover {
    cursor: ${({ hoverStyle }) => (hoverStyle?.color ? 'pointer' : 'initial')};
    .item-name {
      color: ${({ hoverStyle, color }) => (hoverStyle?.color ? hoverStyle?.color : color ? color : 'var(--basic-800)')};
      background-color: ${({ hoverStyle }) => (hoverStyle?.backgroundColor ? hoverStyle?.backgroundColor : 'inherit')};
    }
  }
`;

export const ItemNameSubTitle = styled(ItemName)`
  .item-name {
    text-decoration: ${({ textDecoration }) => (textDecoration ? textDecoration : 'none')};
  }
  .item-subtitle {
    margin-top: 4px;
    font-size: 12px;
    color: var(--basic-500);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${({ lineClamp }) => (lineClamp?.subtitle ? lineClamp?.subtitle : 'initial')};
    -webkit-box-orient: vertical;
  }
`;

export const ItemDate = styled.div`
  text-align: ${props => (!_.isNil(props?.align) ? props.align : 'initial')};
  color: var(--basic-800);
  font-size: 14px;
`;

export const ItemPrice = styled.div`
  text-align: ${props => props.align || 'right'};
  .ori-price {
    min-height: 22px;
    line-height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: var(--disabled);
    text-decoration: line-through;
    ${props => _.isNil(props.origin) && `display: none;`}
  }
  .price {
    min-height: 22px;
    line-height: 22px;
    font-weight: 600;
    color: var(--primary-500);
    font-size: 14px;
  }
  .same-price {
    color: var(--basic-800);
  }
`;

export const ItemStatus = styled.div`
  display: flex;
  align-items: center;
  .item-name {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 600;
    color: ${props => props.color};
  }
`;

export const ItemDescription = styled.div`
  position: relative;
  font-size: 14px;
  color: ${({ color }) => (color ? color : 'var(--basic-800)')};
`;

export const DepositItem = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${props => (props.type === 1 ? 'var(--primary-500)' : 'var(--basic-500)')};
`;

export const NoticeDescItem = styled.div`
  font-size: 14px;
  font-weight: initial;
  color: var(--basic-600);
`;

export const BooleanItem = styled.div`
  text-align: ${props => props?.align || 'initial'};
  span {
    justify-content: ${props => props?.justify || 'flex-start'};
  }
`;

export const BackgroundTable = styled(Table)`
  .ant-table-thead > tr > th {
    border-bottom: none;
  }
  .ant-table-tbody {
    background: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'transparent')};
    tr {
      .ant-table-cell {
        border: solid 1px ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'transparent')} !important;
      }
      .ant-table-cell:first-child {
        border-radius: 8px 0 0 8px;
      }
      .ant-table-cell:last-child {
        border-radius: 0 8px 8px 0;
      }
    }
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'transparent')};
  }
`;
