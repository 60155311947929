import React from 'react';
import Icon from './';
import { ReactComponent as ErrorCircleOutlined } from '@/images/dentall-components/0-icon-fill-close-circle-fill.svg';

const CustomizeIcon = props => {
  return <Icon component={ErrorCircleOutlined} {...props} />;
};

export default CustomizeIcon;

CustomizeIcon.defaultProps = {
  color: 'var(--sheeta--danger-500)',
};
