export const ERR_SSO_AU0015 = 'SSOAU0015'; // sctId failed
export const ERR_SHEETA_0002 = 'SHEETA0002'; // workspace not found by id
export const ERR_SHEETA_0006 = 'SHEETA0006'; // workspace is not belong to the user
export const ERR_404 = '404'; // not found
export const ERR_0002 = 'SOFI0002'; // without permission
export const ERR_0014 = 'SOFI0014'; // 採購服務未開通
export const ERR_0103 = 'SOFI0003'; // 必要資源未找到
export const ERR_0100 = 'SOFI0100'; // 此商品已下架
export const ERR_0101 = 'SOFI0101'; // 此商品尚未開放購買
export const ERR_0102 = 'SOFI0102'; // 此商品已結束購買

export const ERR_OD002 = 'SOFI-OD002'; // 購物車內商品總重量超重
export const ERR_OD003 = 'SOFI-OD003'; // 購物車使用的牙e券不存在
export const ERR_OD004 = 'SOFI-OD004'; // 購物車使用的牙e券失效
export const ERR_OD005 = 'SOFI-OD005'; // 診所剩餘的購物金不足使用
export const ERR_OD006 = 'SOFI-OD006'; // 訂單成立失敗
export const ERR_OD014 = 'SOFI-OD014'; // 庫存不足
export const errorStatus = {
  ERR_SSO_AU0015,
  ERR_SHEETA_0002,
  ERR_SHEETA_0006,
  ERR_404,
  ERR_0103,
  ERR_0100,
  ERR_0101,
  ERR_0102,
};
