import _ from 'lodash';
import EmptyItem from '@/images/icon-default-empty-item.svg';
import { gcsDentaltwStorageUrl } from '@/constants/config';
import { convertCurrencyFormat } from '@/utils/dentall-components/convert';

export const parseProductImageUrl = target => {
  if (_.isEmpty(target)) return EmptyItem;
  if (target.includes('http')) return target;
  return `${gcsDentaltwStorageUrl}/${target}`;
};

export const parseProductPrice = ({ is_ask_little_angel, options = [] }) => {
  if ((_.isEmpty(options) && !_.isArray(options) && options.length <= 0) || !!is_ask_little_angel) {
    return {
      original_price: null,
      price: null,
      isSamePrice: false,
    };
  }
  if (options.length === 1) {
    if (_.isEmpty(options[0]?.values) || !_.isArray(options[0]?.values) || options[0]?.values.length <= 0) {
      return {
        original_price: null,
        price: null,
        isSamePrice: false,
      };
    }

    const isOriPrice = !_.isNil(options[0]?.original_price) && options[0]?.original_price !== 0;
    const isPrice = !_.isNil(options[0]?.price) && options[0]?.price >= 0;
    const isSame = _.isEqual(options[0]?.original_price, options[0]?.price);

    return {
      original_price: isOriPrice ? convertCurrencyFormat(options[0]?.original_price || 0, 'NT$') : null,
      price: isPrice ? convertCurrencyFormat(options[0]?.price || 0, 'NT$') : null,
      isSamePrice: (!isOriPrice && isPrice) || isSame,
    };
  } else {
    if (!options.every(({ values, price }) => !_.isNil(values) && !_.isEmpty(values) && !_.isNil(price))) {
      return {
        original_price: null,
        price: null,
        isSamePrice: false,
      };
    }
    const getPrices = options.map(({ price = 0 }) => price);
    const minReset = Math.min(...getPrices);
    const maxReset = Math.max(...getPrices);
    return {
      original_price: null,
      price:
        minReset === maxReset
          ? `${convertCurrencyFormat(maxReset, 'NT$')}`
          : `${convertCurrencyFormat(minReset, 'NT$')} - ${convertCurrencyFormat(maxReset, 'NT$')}`,
      isSamePrice: true,
    };
  }
};

export const parseButtonText = ({ f, is_ask_little_angel, isOutOfStock, onlyOne, endTheSale, notYatSale }) => {
  if (notYatSale) return f({ id: 'button.not.yat.sale' });
  if (endTheSale) return f({ id: 'button.end.the.sale' });
  if (is_ask_little_angel) return f({ id: 'button.contact.angel' });
  if (isOutOfStock) return f({ id: 'button.sold.out' });

  return onlyOne ? f({ id: 'button.add.to.cart' }) : f({ id: 'button.select.format' });
};
