import React from 'react';
import Icon from './';
import { ReactComponent as InfoCircleOutlined } from '@/images/dentall-components/0-icon-fill-info-circle-fill.svg';
const CustomizeIcon = props => {
  return <Icon component={InfoCircleOutlined} {...props} />;
};

export default CustomizeIcon;
CustomizeIcon.defaultProps = {
  color: 'var(--sheeta--primary-500)',
};
