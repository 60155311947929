import styled from 'styled-components';
import { Button as BtnAntd, Typography } from 'antd';

const AccountContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-btn {
    padding: 0;
  }
`;

const PopoverWrapper = styled(BtnAntd)`
  width: 32px;
  height: 32px;
  border: 0;
`;

const PopoverTitleWrapper = styled.div`
  display: flex;
  padding: 20px 24px 15px;
  border-bottom: 1px solid var(--sheeta--basic-300);
  margin: 0 auto;
`;

const KeypadCardWsName = styled.a`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  color: var(--basic-800);
  list-style: none;
  &:hover {
    color: var(--primary-500);
  }
  &:focus {
    color: var(--primary-500);
  }
  &:active {
    color: var(--primary-500);
  }
`;

const PopoverTitle = styled(Typography.Paragraph)`
  font-size: 16px;
  font-weight: 600;
  color: var(--basic-800);
`;

export { AccountContainer, PopoverWrapper, PopoverTitleWrapper, KeypadCardWsName, PopoverTitle };
