import styled from 'styled-components';
import PropTypes from 'prop-types';
import { KeypadCardWsName, PopoverTitle, PopoverTitleWrapper } from './style';
import { wsUrl } from '@/constants/config';
import { redirectToWsList } from '@/utils/redirect';
import { redirectHrefTo } from '@/utils/dentall-components/string';
import Button from '@/components/Button/Button';

//#region
const TitleWrapper = styled(PopoverTitleWrapper)`
  flex-direction: column;
`;
const SwitchClinic = styled.div`
  cursor: pointer;
  font-size: 14px;
  color: var(--basic-600);

  &:hover {
    color: var(--basic-800);
  }
  &:focus {
    color: var(--basic-800);
  }
`;
const ServiceLink = styled.a`
  width: 100%;
  height: 40px;
  border: 0;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.43;
  display: flex;
  align-items: center;
  box-shadow: none;
  color: var(--dark);
  padding: 10px 16px;
  &:hover {
    color: var(--dark);
    background: var(--basic-200);
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
const LabLink = styled(ServiceLink)`
  margin: 16px 0;
`;
const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const EmptyTitle = styled(PopoverTitle)`
  padding-top: 12px;
`;
const EmptyButton = styled(Button)`
  margin-bottom: 12px;
`;
//#endregion

const KeypadTitle = ({ onClick, wsId, wsName, switchText }) => {
  return (
    <TitleWrapper onClick={onClick}>
      <KeypadCardWsName onClick={() => redirectHrefTo(`${wsUrl}/${wsId}`)}>{wsName}</KeypadCardWsName>
      <SwitchClinic onClick={() => redirectToWsList()}>{switchText}</SwitchClinic>
    </TitleWrapper>
  );
};
const KeypadContent = ({ services }) => {
  const { purchase, lab } = services;
  return (
    <>
      {purchase?.enable && (
        <ServiceLink>
          <img src={purchase?.logo} alt="purchase" />
        </ServiceLink>
      )}
      {lab?.enable && (
        <LabLink href={lab?.link} target={'_blank'}>
          <img src={lab?.logo} alt="lab" />
        </LabLink>
      )}
    </>
  );
};
const KeypadEmptyContent = ({ title, btnText, clickHandler }) => {
  return (
    <EmptyContent>
      <EmptyTitle>{title}</EmptyTitle>
      <EmptyButton buttonText={btnText} type={'secondary-basic'} onClick={clickHandler} />
    </EmptyContent>
  );
};

export { KeypadTitle, KeypadContent, KeypadEmptyContent };

KeypadTitle.propTypes = {
  align: PropTypes.object,
  deviceWidth: PropTypes.number,
  isTabletAndMobile: PropTypes.bool,
  wsId: PropTypes.string,
  wsName: PropTypes.string,
};

KeypadTitle.defaultProps = {
  onClick: () => {},
  wsId: undefined,
  wsName: undefined,
  switchText: undefined,
};

KeypadContent.propTypes = {
  services: PropTypes.object,
};

KeypadContent.defaultProps = {
  services: {},
};

KeypadEmptyContent.propTypes = {
  title: PropTypes.string,
  btnText: PropTypes.string,
  clickHandler: PropTypes.func,
};

KeypadEmptyContent.defaultProps = {
  title: undefined,
  btnText: undefined,
  clickHandler: () => {},
};
