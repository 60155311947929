import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { lineLink } from '@/constants/config';
import {
  BILL_PATH,
  BILL_LIST_PATH,
  CART_PATH,
  CART_PREVIEW_PATH,
  COLLECTION_PATH,
  COUPON_PATH,
  DEPOSIT_PATH,
  NOTIFY_PATH,
  ORDER_PATH,
  ORDER_LIST_PATH,
  SHOP_PATH,
} from '@/constants/routePath';
import Button from '@/components/Button/Button';

//#region
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 64px 0;

  .empty-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  @media screen and (max-width: 767px) {
    margin: 48px 0;
  }
`;
const Wrapper = styled.div`
  text-align: center;
  h6 {
    color: var(--basic-800);
    font-weight: 600;
    margin: 0;
  }
  .btn-wrap {
    margin-top: 24px;
  }
`;
const Image = styled.div`
  img {
    width: 120px;
    height: 120px;
    object-fit: contain;
  }

  @media screen and (max-width: 767px) {
    img {
      width: 100px;
      height: 100px;
    }
  }
`;
const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  font-size: ${props => (!!props.size ? props.size : 16)}px;
  font-weight: 600;
  color: var(--basic-800);

  a {
    border-bottom: solid 1px var(--basic-800);
    &:hover {
      border-bottom: solid 1px var(--primary-500);
    }
  }
`;
const ShopButton = styled(Button)`
  margin-top: 24px;
`;
//#endregion

const parseEmptyContent = ({ type, f, description, navigate }) => {
  switch (type) {
    case SHOP_PATH:
      return (
        <Content size={16}>
          <div>{description}</div>
          <span>{`${f({ id: 'pleaseResetFilters' })}\u00A0`}</span>
          <a href={lineLink} target="_blank" rel="noreferrer">
            {f({ id: 'contactAngel' })}
          </a>
        </Content>
      );
    case CART_PREVIEW_PATH:
      return (
        <Content size={14}>
          <div>{description}</div>
        </Content>
      );
    case BILL_PATH:
    case ORDER_PATH:
      return (
        <Content size={16}>
          <div>{description}</div>
        </Content>
      );
    case BILL_LIST_PATH:
    case ORDER_LIST_PATH:
    case CART_PATH:
    case COLLECTION_PATH:
      return (
        <div>
          <Content size={18}>
            <div>{description}</div>
          </Content>
          <ShopButton
            onClick={() => navigate(`/${SHOP_PATH}`)}
            buttonText={f({ id: 'button.browse.shop' })}
            size={'large'}
            type={'primary'}
          />
        </div>
      );
    case NOTIFY_PATH:
    case COUPON_PATH:
    case DEPOSIT_PATH:
      return (
        <Content size={18}>
          <div>{description}</div>
        </Content>
      );
    default:
      return null;
  }
};

const Empty = ({ type, image, description }) => {
  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();

  return (
    <Container>
      <div className="empty-wrap">
        <Wrapper>
          <Image>
            <img src={image} alt="empty product" />
          </Image>
          {parseEmptyContent({ type, f, description, navigate })}
        </Wrapper>
      </div>
    </Container>
  );
};

export default Empty;

Empty.propTypes = {
  type: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
};

Empty.defaultProps = {
  type: '',
  image: '',
  description: undefined,
};
