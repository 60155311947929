import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import settingReducer from './settingSlice';
import workspaceReducer from './workspaceSlice';

const homePageReducer = combineReducers({
  authentication: authReducer,
  setting: settingReducer,
  ws: workspaceReducer,
});

export default homePageReducer;
