import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const orderSlice = createSlice({
  name: 'orderPage/orderList',
  initialState: {
    orderList: undefined,
  },
  reducers: {
    getOrderList: (state, action) => {
      if (!!action.payload) {
        return {
          ...state,
          list: action.payload?.results,
          total: action.payload?.total,
          currentTotalLens: !_.isNil(action.payload?.results) ? action.payload?.results.length : state.currentTotalLens,
        };
      }
    },
  },
});

export const { getOrderList } = orderSlice.actions;

export default orderSlice.reducer;
