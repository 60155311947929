import _ from 'lodash';

export const normalizeString = (target, targetStr = '', replaceStr = '') => {
  return !_.isEmpty(target) && _.isString(target) ? target.replace(targetStr, replaceStr) : target;
};

export const splitStr = (str, split) => {
  if (!str || !split || !_.isString(str) || str.length <= 1) return [str];
  return str.split(split);
};

export const getHref = () => {
  return window.location.href;
};

export const getSearch = () => {
  return window.location.search;
};

export const redirectHrefTo = href => {
  if (_.isEmpty(href)) {
    window.location.href = '/';
  } else {
    window.location.href = href;
  }
};

const recursiveParams = (param, path, searchParams) => {
  if (!_.isNil(param)) {
    if (_.isPlainObject(param)) {
      for (const [k, v] of Object.entries(param)) {
        recursiveParams(v, path + '[' + k + ']', searchParams);
      }
    } else if (Array.isArray(param)) {
      param.forEach(v => recursiveParams(v, path + '[]', searchParams));
    } else {
      searchParams.append(path, param);
    }
  }
};
export const appendStr = (oriStr, appendQ) => {
  const searchParams = new URLSearchParams();
  Object.entries(appendQ).forEach(([key, value]) => {
    recursiveParams(value, key, searchParams);
  });
  return `${oriStr}?${searchParams.toString()}`;
};

export const strLimit = (value, limit) => {
  if (_.isEmpty(value) || !_.isString(value)) return value;

  try {
    let len = 0;
    let currentStr = '';
    const split = value.split('');
    for (let i = 0; i < split.length; i++) {
      // eslint-disable-next-line no-control-regex
      const regex = new RegExp('[^\x00-\xff]', 'g');
      if (regex.test(split[i])) len += 2;
      else len++;
      if (len > limit) {
        break;
      }
      currentStr += split[i];
    }
    return currentStr;
  } catch (e) {
    console.error(e);
    return value;
  }
};

export const resetInputSelectionPos = e => {
  const caret = e.target.selectionStart;
  const element = e.target;
  window.requestAnimationFrame(() => {
    element.selectionStart = caret;
    element.selectionEnd = caret;
  });
};

export const arrayFromSame = (length, data) => {
  if (length <= 0 || _.isEmpty(data)) return [];
  return Array.from({ length }, () => data);
};

export const flatten = (arr, target, deep = Infinity) => {
  if (_.isNil(arr)) return [];
  if (!target) return _.flattenDepth(arr, deep);
  return _.flattenDepth(
    arr.map(c => (_.isNil(c?.[target]) ? c : _.flattenDepth([c, flatten(c?.[target], target)], deep))),
    deep,
  );
};
