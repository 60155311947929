import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { saveWorkspaceId } from '@/utils/token';

const workspaceSlice = createSlice({
  name: 'wsPage/workspace',
  initialState: {
    wsList: undefined,
    ws: undefined,
    member: undefined,
  },
  reducers: {
    getWsList: (state, action) => {
      if (!!action.payload) {
        return {
          ...state,
          wsList: action.payload,
        };
      }
    },
    getWs: (state, action) => {
      if (!!action.payload) {
        if (!_.isEmpty(action.payload?.workspace?._id)) {
          saveWorkspaceId(action.payload?.workspace._id);
        }
        return {
          ...state,
          ws: action.payload?.workspace,
          member: action.payload?.member,
        };
      }
    },
  },
});

export const { getWsList, getWs } = workspaceSlice.actions;

export default workspaceSlice.reducer;
