import React, { useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import { parseProductImageUrl } from '@/components/Product/ProductItem/utils';
import Loading from '@/components/Loading';

const Container = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    align-self: center;
  }
`;
const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
`;
const LazyLoadWrap = styled(LazyLoad)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  aspect-ratio: 1/1;
`;
const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  bottom: 0;
  transform: translateY(-50%);
  //animation: ${loadingAnimation} 1s infinite;
`;
const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  align-self: center;
  aspect-ratio: 1/1;
`;

const ImageWrapper = ({ thumbnail, alt }) => {
  const refPlaceholder = useRef();
  const removePlaceholder = () => {
    refPlaceholder.current.remove();
  };

  return (
    <Container>
      <Placeholder ref={refPlaceholder}>
        <Loading inContent={true} isTip={false} />
      </Placeholder>
      <LazyLoadWrap debounce={300}>
        <StyledImage
          onLoad={removePlaceholder}
          onError={removePlaceholder}
          src={parseProductImageUrl(thumbnail)}
          alt={alt}
        />
      </LazyLoadWrap>
    </Container>
  );
};

export default ImageWrapper;

ImageWrapper.propTypes = {
  thumbnail: PropTypes.string,
  alt: PropTypes.string,
};

ImageWrapper.defaultProps = {
  thumbnail: '',
  alt: '',
};
