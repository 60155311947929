import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const collectionSlice = createSlice({
  name: 'collectionPage/collection',
  initialState: {
    changedItems: [],
    collection: undefined,
    total: null,
    currentTotalLens: 0,
  },
  reducers: {
    getCollections: (state, action) => {
      if (!!action.payload) {
        return {
          ...state,
          changedItems: action.payload.item_compare_reports,
          collection: action.payload?.results,
          total: action.payload?.total,
          currentTotalLens: !_.isNil(action.payload?.results) ? action.payload?.results.length : state.currentTotalLens,
        };
      }
    },
  },
});

export const { getCollections } = collectionSlice.actions;

export default collectionSlice.reducer;
