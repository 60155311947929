import _ from 'lodash';
import { useLocation, matchRoutes } from 'react-router-dom';

// routes must have at least one [{path: 'YOUR_PATH'}]
const useCurrentPath = ({ routes }) => {
  const location = useLocation();
  if (_.isEmpty(routes)) return undefined;
  try {
    const [data] = matchRoutes(routes, location);
    const { route } = data;
    return route?.path || undefined;
  } catch (e) {
    return undefined;
  }
};

export default useCurrentPath;
